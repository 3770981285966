<template>
  <div class="scheme">
    <BaseDialog
      :title="ruleForm.id ? '修改透析方案' : '新增透析方案'"
      :isshow="visible"
      @handleShow="handleShow"
      width="80%"
      height="auto"
    >
      <div class="schemeBox">
        <div class="templateBut">
          <el-button class="color-main" type="primary" @click="openTemplate">
            <i class="fa fa-search"></i>调取模板
          </el-button>
        </div>
        <div class="contents">
          <el-scrollbar>
            <div class="padRight-20">
              <div class="userItem">
                <el-form
                  ref="ruleFormsRef"
                  :model="ruleForm"
                  :rules="Rule.DIALYSIS"
                  label-width="104px"
                  label-position="right"
                  :inline="true"
                  class="demo-ruleForm col-333 lable-104"
                >
                  <el-form-item label="透析方式：" prop="dict_dialysis_type">
                    <el-select
                      v-model="ruleForm.dict_dialysis_type"
                      @change="dialysisTypeCha"
                      placeholder="请选择透析方式"
                    >
                      <el-option
                        v-for="item in dictList[100000000]"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="干体重：" prop="dry_weight">
                    <el-input
                      v-model="ruleForm.dry_weight"
                      placeholder="请输入干体重"
                    >
                      <template #append>KG</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="附加体重：">
                    <el-input
                      v-model="ruleForm.add_weight"
                      placeholder="请输入"
                    >
                      <template #append>KG</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item class="lstwo" label="透析时长：">
                    <el-input
                      v-model="ruleForm.dialysis_hour"
                      placeholder="请输入"
                    >
                      <template #append>时</template>
                    </el-input>
                    <el-input
                      v-model="ruleForm.dialysis_min"
                      placeholder="请输入"
                    >
                      <template #append>分</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="预脱水量：">
                    <el-input
                      v-model="ruleForm.max_tuoshui_num"
                      placeholder="请输入"
                    >
                      <template #append>ml</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item class="lstwo" label="血流速：">
                    <el-input
                      v-model="ruleForm.xueliu_speed_min"
                      placeholder="请输入"
                    >
                      <template #append>至</template>
                    </el-input>
                    <el-input
                      v-model="ruleForm.xueliu_speed_max"
                      placeholder="请输入"
                    >
                      <template #append>ml/min</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="回血量：">
                    <el-input
                      v-model="ruleForm.huixue_num"
                      placeholder="请输入"
                    >
                      <template #append>ml</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="回血泵速：">
                    <el-input
                      v-model="ruleForm.huixue_beng_speed"
                      placeholder="请输入"
                    >
                      <template #append>ml/min</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="超滤方式：">
                    <el-select
                      v-model="ruleForm.dict_chaolv_mode"
                      placeholder="请选择超滤方式"
                    >
                      <el-option
                        v-for="item in dictList[339400000]"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="总充水量：">
                    <el-select
                      v-model="ruleForm.dict_flush_mode"
                      placeholder="请选择总充水量"
                    >
                      <el-option
                        v-for="item in dictList[339500000]"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="护理等级：">
                    <el-select
                      v-model="ruleForm.dict_huli_level"
                      placeholder="请选择护理等级"
                    >
                      <el-option
                        v-for="item in dictList[119000000]"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="透析频次：">
                    <el-select
                      v-model="ruleForm.dict_dialysis_cycle"
                      placeholder="请选择透析频次"
                    >
                      <el-option
                        v-for="item in dictList[117000000]"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="制定时间：">
                    <el-date-picker
                      v-model="ruleForm.make_time"
                      type="date"
                      value-format="YYYY-MM-DD"
                      placeholder="请选择制定时间"
                    ></el-date-picker>
                  </el-form-item>
                  <el-form-item label="制定人：">
                    <el-select
                      v-model="ruleForm.assign_user_id"
                      placeholder="请选择制定人"
                    >
                      <el-option
                        v-for="item in userNameList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-form>
              </div>
              <div class="userItem">
                <el-form
                  ref="ruleFormRef"
                  :model="ruleForm"
                  label-width="104px"
                  label-position="right"
                  :inline="true"
                  class="demo-ruleForm col-333 lable-104"
                >
                  <el-form-item label="透析液温度：">
                    <el-input
                      v-model="ruleForm.touxiye_temp"
                      placeholder="请输入"
                    >
                      <template #append>℃</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="透析液流速">
                    <el-input
                      v-model="ruleForm.touxiye_speed"
                      placeholder="请输入"
                    >
                      <template #append>ml/min</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="K：">
                    <el-input v-model="ruleForm.k" placeholder="请输入">
                      <template #append>mmol/L</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="Ca：">
                    <el-input v-model="ruleForm.ca" placeholder="请输入">
                      <template #append>mmol/L</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="Na：">
                    <el-input v-model="ruleForm.na" placeholder="请输入">
                      <template #append>mmol/L</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="HCO3-：">
                    <el-input v-model="ruleForm.hco3" placeholder="请输入">
                      <template #append>mmol/L</template>
                    </el-input>
                  </el-form-item>
                </el-form>
              </div>
              <div class="userItem">
                <el-form
                  ref="ruleFormRef"
                  :model="ruleForm"
                  label-width="104px"
                  label-position="right"
                  :inline="true"
                  class="demo-ruleForm col-333 lable-104"
                >
                  <el-form-item label="抗凝剂：">
                    <el-select
                      v-model="ruleForm.dict_kangning"
                      placeholder="请选择抗凝剂"
                    >
                      <el-option
                        v-for="item in dictList[116000000]"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item class="select" label="首剂：">
                    <el-input
                      v-model="ruleForm.kangning_shouji_num"
                      placeholder="请输入"
                    >
                      <template #append>
                        <el-select
                          v-model="ruleForm.dict_kangning_shouji_unit"
                          placeholder="请选择单位"
                        >
                          <el-option
                            v-for="item in dictList[157000000]"
                            :key="item.id"
                            :label="item.name"
                            :value="item.code"
                          ></el-option>
                        </el-select>
                      </template>
                    </el-input>
                  </el-form-item>
                  <el-form-item class="lstwo" label="维持时间：">
                    <el-input
                      v-model="ruleForm.kangning_add_time_hour"
                      placeholder="请输入"
                    >
                      <template #append>时</template>
                    </el-input>
                    <el-input
                      v-model="ruleForm.kangning_add_time_min"
                      placeholder="请输入"
                    >
                      <template #append>分</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="追加维持：">
                    <el-select
                      v-model="ruleForm.dict_kangning_add"
                      placeholder="请选择追加维持"
                    >
                      <el-option
                        v-for="item in dictList[116000000]"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item class="select" label="追加维持：">
                    <el-input
                      v-model="ruleForm.kangning_add_num"
                      placeholder="请输入剂量"
                    >
                      <template #append>
                        <el-select
                          v-model="ruleForm.dict_kangning_add_num_unit"
                          placeholder="请选择单位"
                        >
                          <el-option
                            v-for="item in dictList[157000000]"
                            :key="item.id"
                            :label="item.name"
                            :value="item.code"
                          ></el-option>
                        </el-select>
                      </template>
                    </el-input>
                  </el-form-item>
                </el-form>
              </div>
              <div class="userItem">
                <el-form
                  ref="ruleFormRef"
                  :model="ruleForm"
                  label-width="104px"
                  label-position="right"
                  :inline="true"
                  class="demo-ruleForm col-333 lable-104"
                >
                  <el-form-item label="血透器：">
                    <el-select
                      v-model="ruleForm.dict_touxiqi"
                      placeholder="请选择血透器"
                    >
                      <el-option
                        v-for="item in dictList[113000000]"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="血滤器：">
                    <el-select
                      v-model="ruleForm.dict_xuelvqi"
                      placeholder="请选择血滤器"
                    >
                      <el-option
                        v-for="item in dictList[114000000]"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="灌流器：">
                    <el-select
                      v-model="ruleForm.dict_guanliuqi"
                      placeholder="请选择灌流器"
                    >
                      <el-option
                        v-for="item in dictList[115000000]"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="置换方式：">
                    <el-select
                      v-model="ruleForm.dict_zhihuan_type"
                      :disabled="isDisabled"
                      placeholder="请选择置换方式"
                    >
                      <el-option
                        v-for="item in dictList[118000000]"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="置换总量：">
                    <el-input
                      :disabled="isDisabled"
                      v-model="ruleForm.zhihuan_num"
                      placeholder="请输入"
                    >
                      <template #append>L</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="" prop="name"> </el-form-item>
                  <el-form-item class="lstwo" label="置换流速：">
                    <el-input
                      :disabled="isDisabled"
                      v-model="ruleForm.zhihuan_speed_min"
                    >
                      <template #append>-</template>
                    </el-input>
                    <el-input
                      :disabled="isDisabled"
                      v-model="ruleForm.zhihuan_speed_max"
                    >
                      <template #append>ml/min</template>
                    </el-input>
                  </el-form-item>
                </el-form>
              </div>
              <div class="userItem">
                <el-form
                  ref="ruleFormRef"
                  :model="ruleForm"
                  label-width="104px"
                  label-position="right"
                  :inline="true"
                  class="demo-ruleForms col-333 lable-104"
                >
                  <el-form-item class="textarea" label="备注：">
                    <el-input
                      v-model="ruleForm.remark"
                      maxlength="1000"
                      :rows="5"
                      placeholder="请输入备注"
                      show-word-limit
                      type="textarea"
                    />
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </el-scrollbar>
        </div>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button
            class="color-cancel"
            type="primary"
            @click="visible = false"
          >
            取消
          </el-button>
          <el-button
            @click="saveDialy"
            :loading="saveLoading"
            class="color-determine"
            type="primary"
          >
            确定
          </el-button>
        </div>
      </template>
    </BaseDialog>
    <templateList
      v-if="isTemplateList"
      ref="templateListRef"
      @tempData="getTempData"
    ></templateList>
  </div>
</template>
<script>
import { reactive, toRefs, ref, nextTick } from 'vue'
import service from '@/utils/request'
import Rule from '@/utils/rule'
import { ElMessage } from 'element-plus'
import { ruleTimeYear } from '@/utils/tool.js'
import BaseDialog from '@/components/Dialog/index.vue'
import templateList from './templateList.vue'
export default {
  name: 'dialysisScheme',
  components: {
    BaseDialog,
    templateList,
  },
  emits: ['refreshDataList'],
  setup(props, ctx) {
    const templateListRef = ref(null)
    const userInfo = JSON.parse(localStorage.getItem('ms_userData'))
    const state = reactive({
      dataForm: {},
      ruleForm: {},
      dictList: {},
      userNameList: [
        {
          name: localStorage.getItem('ms_username'),
          code: userInfo.uid,
          id: userInfo.uid,
        },
      ],
      Rule: Rule,
      id: '',
      visible: false,
      isTemplateList: false,
      saveLoading: false,
      ruleFormsRef: null,
      isDisabled: true,
    })

    const initData = async (id, row, item, type) => {
      state.visible = true
      state.id = id
      state.dictList = row
      formInit()
      if (item && item.id) {
        state.ruleForm = item
        dialysisTypeCha(item.dict_dialysis_type)
        if (type === '2') {
          state.ruleForm.id = ''
        }
      }
    }

    const formInit = () => {
      state.ruleForm = {}
      state.ruleForm = {
        assign_user_id: userInfo.uid,
        dict_dialysis_type: '100110000',
        add_weight: 0,
        dialysis_hour: 4,
        dialysis_min: 0,
        xueliu_speed_min: 250,
        dict_huli_level: '119110000',
        make_time: ruleTimeYear(new Date()),
        touxiye_temp: '36.5',
        touxiye_speed: '500',
        k: '2',
        ca: '1.5',
        na: '138',
        hco3: '35',
        kangning_add_time_hour: 4,
        kangning_add_time_min: 0,
        dict_kangning_shouji_unit: '157130000',
        dict_kangning_add_num_unit: '157130000',
      }
    }

    const dialysisTypeCha = (val) => {
      if (
        val === '100120000' ||
        val === '100150000' ||
        val === '1388016580952096' ||
        val === '1445308508667936'
      ) {
        state.isDisabled = false
      } else {
        state.isDisabled = true
      }
    }

    const handleShow = (val) => {
      state.visible = val
    }

    const openTemplate = () => {
      state.isTemplateList = true
      nextTick(() => {
        templateListRef.value.initData(state.dictList)
      })
    }
    const getTempData = (item) => {
      state.ruleForm = item
      dialysisTypeCha(item.dict_dialysis_type)
      state.ruleForm.id = ''
    }

    const saveDialy = () => {
      state.ruleFormsRef.validate(async (valid) => {
        if (valid) {
          state.ruleForm.patient_id = state.id
          let url = ''
          if (state.ruleForm.id) {
            state.ruleForm.patient_dialysis_plan_id = state.ruleForm.id
            url = '/api/patient_dialysis/edit_patient_dialysis_plan'
          } else {
            url = '/api/patient_dialysis/create_patient_dialysis_plan'
          }
          state.saveLoading = true
          let res = await service.post(url, state.ruleForm)
          state.saveLoading = false
          if (res.code === 0) {
            ElMessage.success(res.msg)
            state.visible = false
            ctx.emit('refreshDataList')
          }
        }
      })
    }

    return {
      ...toRefs(state),
      initData,
      handleShow,
      templateListRef,
      openTemplate,
      saveDialy,
      dialysisTypeCha,
      getTempData,
    }
  },
}
</script>

<style scoped lang="scss">
.templateBut {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  margin-bottom: 10px;
}
.contents {
  height: 500px;
  overflow: hidden;
  .userItem {
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 30px;
  }
  .demo-ruleForms .el-form-item {
    width: 100%;
    margin-right: 0;
  }
  .el-form-item {
    width: 30%;
    margin-right: 5%;
    &.addr {
      width: 65%;
      margin-right: 0;
    }
    &.textarea {
      &:deep(.el-form-item__content) {
        max-width: 100%;
      }
    }
    &:deep(.el-form-item__content) {
      width: calc(100% - 104px);
      max-width: 300px;
    }
    &:deep(.el-input-group__append .el-select) {
      width: auto;
    }
  }
  .el-form-item:nth-child(3n + 3) {
    margin-right: 0;
  }
}

.dialog-footer {
  text-align: right;
  margin-top: 20px;
  .color-cancel {
    background: #ffffff;
    color: #3166ae;
    margin-right: 10px;
  }
}
</style>
